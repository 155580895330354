.Loading{
    .loading-logo{
        width: fit-content;
        margin: auto;
        animation: App-logo-spin infinite 2s linear;
    }
}

@keyframes App-logo-spin {
    0% {
      opacity: 1;
    }
    50% {
      opacity: .2;
    }
    100% {
        opacity: 1;
      }
  }
  
.login{
    .EmailVerification{
        .img-container{
            margin: auto;
            max-width: 250px;
            img{
                width: 100%;
                border-radius: 50%;
            }
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Tajawal&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

:root { 
    --primeryColor:#7d749e;
    --primeryColor2:var(--primeryColor2);
    --secColor: #fff;
    --secwhiteColor:#f5f5f5;
    --thirdColor:#eee;
    --text-color: #323232;
    --box-shadow: inset 0px 11px 8px 0px #00000016;
    --PALETTES-COLLOR1: #F9F871;
    --PALETTES-COLLOR2: #004534;
    --bs-blue: var(--primeryColor);
    --bg-hero-rtl: linear-gradient(to left bottom, #5e4894, #6d5e99, #7d749e, #7d749e, #7d749e);
    --bg-hero-ltr: linear-gradient(to right bottom, #5e4894, #6d5e99, #7d749e, #7d749e, #7d749e);
  }
/* #5E79A7 #7d749e #004534 */
body, .currentcolor{   
color: var(--text-color);
}

.small, small {
    font-size: 0.5em;
}

button{
    transition: .3s;
    a{
        color: currentColor;
        &:hover{
            color: currentColor;
        }
    }
}

.rtl-font{
    font-family: 'Tajawal', sans-serif;
}
.ltr-font{
    font-family: 'Noto Sans', sans-serif;
}
.btn:hover {
    color: #212529;
    background-color: #ddc7ff;
}
.btn:focus{
    box-shadow: 0 0 0 0;
    outline: 0;
}
.prm-btn{
    border:1px solid var(--primeryColor);
    background-color: var(--primeryColor);
    color: var(--secColor);
    border-radius: 5px;
    padding: 7px 10px;
    outline: none;
}
.prm-btn:hover{
    background-color: #0000;

    color: var(--primeryColor);

}
.main-btn{
    border:1px solid var(--primeryColor);
    background-color: var(--secColor);
    color: var(--primeryColor);
    border-radius: 5px;
    padding: 7px 10px;
}
.nav-btn{
    border:1px solid #0000;
    border-radius: 5px;
    padding: 7px 10px;
    outline: none;
    color: var(--secwhiteColor);
}

.nav-btn:hover{
    color: var(--secwhiteColor);
    background: var(--primeryColor);
}
.border-primary {
    border-color: var(--primeryColor2)!important;
}
.bg-primary {
    background-color: var(--primeryColor2)!important;
}
.btn-primary {
    color: var(--secColor);
    background-color:var(--primeryColor2);
    border-color: var(--primeryColor2);
    &:hover,&:active{
        background-color:var(--secColor);
        color: var(--primeryColor2);
        border-color: var(--primeryColor2);
    }
}

.btn-outline-primary {
    color: var(--primeryColor2);
    border-color: var(--primeryColor2);
    &:hover,&:active{
        background-color:var(--primeryColor2);
        border-color: var(--primeryColor2);
        color: var(--secColor);
    }
}
.header, nav, .hero-section, .defenition-section, 
.extensions-section, .footer{
    width: 100%;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: var(--primeryColor);
}

.navbar{
    border-bottom: 1px solid #0000;
    transition: .25s;
    .lang-btn{
        background: #f5f5f5;
        border: 1px solid #0000;
    }
    .nav-btn{
        border-color: #fff;
        &:hover{
            background: #eee;
            color: #5e4894;
        }
    }
    &.navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
    &.navbar-bg-white{
        background-color: white;
        color: var(--text-color) ;
        border-bottom: 1px solid #ddd;
        .lang-btn{
            background: #0000;
            border-color: #7d749e;
        }
        &.navbar-dark .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
        .navbar-brand{
           color: var(--primeryColor);
        }
        &.navbar-dark .nav-link,
        .navbar-nav .show>.nav-link {
            color: var(--text-color);
            
        }
        &.navbar-dark .nav-link.active{
            color: #18856f;
        }
        .nav-btn{
            color: var(--text-color);
        }
        .nav-btn:hover{
            color: var(--secColor);
            background: var(--primeryColor);
        }
    }
    .navbar-brand{
        svg{
            margin: 0 3px;
            height: 34px;
        }
    }
    .navbar-nav {
        @media (max-width: 787.98px) {
            #navbarDropdownMenu{
                list-style: none;
                padding: 0;
                h6{
                    margin-left: 1rem;
                    text-transform: uppercase;
                    border-bottom: 1px solid #18856f;
                    color: #18856f;
                    padding-bottom: 7px;
                }
                li{
                    padding: 0;
                }
                li .dropdown-item {
                    &:focus, &:hover {
                    background-color: #0000;
                    color: #036361;
                }
            }
        }
        }
        .nav-item{
            padding-right: .7rem;

            .dropdown-toggle:hover::before,
            .dropdown-toggle:hover::after{
                background-color: var(--thirdColor);
            }
        }
        
        .dropdown-toggle{
            position: relative;
            padding-right: 1rem;
            &::after,
            &::before {
                content: "";
                display: block;
                margin: 0;
                position: absolute;
                top: 50%;
                left: auto;
                bottom: auto;
                right: 0;
                transition: all 300ms ease 0s;
                width: 10px;
                height: 2px;
                border: none;
                background-color: var(--primeryColor);
                transition: all 300ms ease 0s;
            }
            
            &.show{
                &::before,
                &::after{
                    background-color: var(--text-color);
                }
                &:hover::before,
                &:hover::after{
                    background-color: var(--primeryColor);
                }
            }
            &::after{
                transform: rotate(45deg);
                right: -5px;
            }    
            &::before {
                transform: rotate(-45deg);
                right: -11px;
            }
            &.show::after{
                transform: rotate(-45deg);
            right: -8px;
            }
                
            &.show::before {
                transform: rotate(45deg);
                right: -8px;
            }
        }
        .dropdown{
            position: static;
            margin-right: 5px;
        }
        .dropdown-menu{
            transition: .3s;
            border: none;
            border-radius: 0;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            font-size: .95rem;
            color: var(--text-color);
            width: 100%;
            &[data-bs-popper] {
                top: 100%;
                left: 0;
                margin-top:-1px;
            }
            @media (min-width: 786px) {
                position: absolute;
                z-index: 1000;
                display: block;
                width: 100%;
                left: 0;
                top: -350px;
                transition: 200ms;
                padding: 50px 5px;
                .dropend, .dropstart, .dropup {
                    position: unset;
                }
            }
            @media (max-width: 787.98px) {
                padding: 30px 10px;
                border-bottom: 1px solid #ddd;
                .col{
                    padding: 15px 5px;
                }
            }
            h6{
                margin-left: 1rem;
                text-transform: uppercase;
                border-bottom: 1px solid #18856f;
                color: #18856f;
                padding-bottom: 7px;
            }
            li .dropdown-item {
                &:focus, &:hover {
                background-color: #0000;
                color: #036361;
            }
            }
    
        }
        .navbar-brand{
            transition: all 300ms ease 0s;
        }
    }
    @media (max-width: 786px) {
        .navbar-collapse{
            padding: .5rem ;
            .navbar-nav{
                padding: 0!important;
            }
        }
    }
    
}
@media (max-width: 575.98px){
    .text-xs-center {
    text-align: center!important;
}
}

/* ---------------- Hero Section ----------------*/
.hero-section{
    padding-top: 50px;
    height: 800px;
    display: block;
    padding-top: 200px;
    color: var(--secColor);
    position: relative;
    z-index: 6;
    overflow: hidden;
    background: var(--secColor);
    &::after{
        transition: all .3s;
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        border: none;
        @media (min-width: 993px){
            clip-path: polygon(0 0, 200% 0, 0 80%, 0% 50%);
        }
    }
    &::after{
        top: 0%;
        left: 0%;
        background-image: var(--bg-hero-ltr);
        height: 100%;
        width: 100%;
        z-index: 5;
        transition: .5s;
    }
    &.rtl-dir{
        @media (min-width: 993px){
            &::after{
                clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 0% 50%);
                background-image: var(--bg-hero-rtl);
            }
        }
    }
    .intro-text-container{
        text-transform: capitalize;
        margin-bottom: 50px;
        position: relative;
        z-index: 6;
        &.rtl-dir{
            .intro-text{
                text-align: start;
            }
        }
    }
    .intro-btn-container{
        position: relative;
        display: block;
        z-index: 6;
        @media (max-width: 992.98px){
            text-align: center;
        }
        .intro-btn{
            background-color: var(--secColor);
            color: var(--primeryColor);
            font-size: 16px;
            margin-right: auto;
            @media (max-width: 992.98px){
                margin: auto;
            }
        }   
    }  
    .intro-pic{
        z-index: 6;
        position: relative;
        transition: .3s;
        top: -20%;
        img {
            position: relative;
            width: 100%;
            height: 450px;
            transform: rotateY(180deg);
            border-radius: 5px;
            box-shadow:  0px 1px 25px 2px #7a7a7a;
        }
    }
    &.rtl-dir{
        .intro-pic{  
            img {
                transform: rotateY(0deg);
            }
        }
    }
    @media (max-width: 992.98px) {
        &::after {
            top: 0;
            left: 0;
            background-color: var(--primeryColor);
            height: 100%;
            width: 100%;
            border-radius: 0;
            z-index: 5;
        }
        & .intro-text{
            text-align: center;
        }
        & .intro-btn{
            margin: auto;
        }  
        & .intro-pic-container{
            display: none;
        }
        .col-lg-8 .card-big-img{
            display: none;
        }
        .company-logo{
            height: 50px;
        }
      }
}
@media (max-width: 992.98px) {
    .col-lg-8 .card-big-img{
        display: none;
    }
    .company-logo{
        height: 50px;
    }
  }

.intro-icons{
    padding: 75px 0;
    background-color: var(--secColor);
    .img-container{
        text-align: center;
        height: 250px;
        width: 85%;
        padding: 10px;
        border-radius: 7px;
        padding: .5rem;
        height: max-content;
        text-align: center;
        span{
            border-radius: 7px;
            padding: .5rem;
            display: block;
            background-color: #7d749e41;
            width: 3.5rem;
            color: var(--primeryColor);
            margin:auto;
            svg{
                width: 100%;
            }
        }
        h5{
            margin: auto;
            padding-bottom: .5rem;
            width: max-content;
            padding-top: 1rem;
            text-transform: capitalize;
            border-bottom: 2px solid var(--primeryColor);
        }
        p{
            margin-top: 1.5rem;
        }
        
    }
}


.extensions-section{
    padding: 50px 0 100px;
    background-color: var(--secColor);
    h1{
        text-align: center;
        width: max-content;
        margin: 60px auto;
        color: var(--primeryColor);
    }
    .ext-card{
        border: 1px solid #ddd;
        border-radius: 4px;
        margin: 1.5rem auto;
        .img-container{
            width: 100%;
            img{
                border-radius: 3px 3px 0 0;
                width: 100%;
                transition: .3s;
            }
        }
        .ext-card-body{
            padding: 0 5px 5px;
            .card-outer-infos{
                padding-top: 1rem;
                display: flex!important;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
            }
            h6{
                margin: .5rem 1rem;
            }
        }
    }
    .extensions-group {
        .card-container{
            margin:  0;
            padding: 10px;
            .card{
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 15px;
                border: 1px solid #ddd;
                &.row{
                    align-items: stretch;
                    flex-direction: row
                }
                .card-content, .card-big-img{
                    margin: 0;
                    padding: 0;
                }
                .card-big-img{
                    height: 200px;
                    svg{
                        width: 100%;
                        height: 100%;
                        opacity: .35;
                        padding: 1rem;
                    }
                }
                .card-header{
                    background-color: #0000;
                    margin-bottom: 15px;
                    .card-img{
                        width: 32px;
                        margin-right: 5px;
                        svg{
                            width: 32px;
                            fill: #525252;
                        }
                    }
                    .card-img, h5{
                        display: inline-block;
                        margin-top: auto;
                        width: max-content;
                    }
                }
                .link-btn{
                    width: max-content;
                    margin-left: auto;
                    margin-top: auto;
                    a{
                        text-decoration: none;
                        color: unset;
                        &:hover{
                            color: var(--primeryColor);
                        }
                    }
                }
            }
        }
    }
}

  /* --------------------------------------------- */

  .modal-footer, .modal-header {
     border-top: none;
     border-bottom: none;
}
.modal-body{
    padding: 0 2rem;
    .form-control:focus {
        border-width: 2px;
    }
    .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
        opacity: 1;
        color: #4f328d;
        background: var(--secColor);
        transform: scale(.85) translateY(-1.75rem) translateX(.35rem);
    }
    span > a{
        font-weight: 500;
        color:#6d5e99;
        font-size: 13px;
    }

}


.modal-body input{
    margin: 2rem auto;
}

.form-control:focus {
    color: #212529;
    background-color: var(--secColor);
    border-color: var(--primeryColor);
    outline: 0;
    box-shadow: 0 0 0 0;
}

.modal-inner-footer{
    margin: 1rem auto;
    a{
        text-decoration: none;
        color: unset;
    }
    .btn.prm-btn{
        padding: 5px 1rem;
    }
    span{
        font-size: 14px;
        a{
        font-weight: 500;
        color: #6d5e99;
    }
}

}

#login-page.modal{
    width: 100%;
    background: white;
}
#login-page.modal .modal-dialog{
    width: 100%;

}

.modal-body input[type=range] {
    margin: auto auto 2rem;
    &::-webkit-slider-thumb {
        background: var(--primeryColor2);
      }
}

.Prices{
    h1{
        text-align: center;
        width: max-content;
        margin: 60px auto;
        color: var(--primeryColor);
    }
    .card{
        .card-title{
            margin: 0;
        }
        box-shadow: none;
        h4{
            padding-left: 1rem;
        }
        text-align: left;
        align-items: center;
    }
    ul{
        
        list-style: url('../images/checkIcon.svg');
        li{
            margin: 1rem 0 1rem 1rem;
        }
    }

    .standardCard{
        color: #434334;
        border-color: var(--thirdColor);
        border-radius: 12px;
        button{
            border-color: var(--primeryColor);
            border-radius: 8px;
            color: var(--primeryColor);
            &:hover{
                border-color: var(--secColor);
            }
        }
    }
    
    .fillCard{
        background-color: var(--primeryColor);
        color: var(--thirdColor);
        border-color: var(--primeryColor);
        border-radius: 12px;
        button{
            color: var(--primeryColor);
            background-color: var(--secwhiteColor);
            border-radius: 8px;
        }
        h1{
            color: var(--thirdColor);
        }
    }
    
    .outLineCard{
        color: #434334;
        border-color: var(--primeryColor);
        border-radius: 12px;
        button{
            background-color: var(--primeryColor);
            border-radius: 8px;
            color: var(--thirdColor);
        }
    }
    
    .rangeMaxMin{
        color: var(--secColor);
        margin-bottom: 0;
        span{
            padding: 2px 3px;
            border-radius: 3px;
            font-size: 10px;
            background-color: var(--primeryColor);
        }
    }

}

.addnewstock{
    width: 50%;
    @media (max-width: 765.98px) {
        width: 80%;
      }
      @media(max-width: 574.98px) {
        width: 95%;
      }
}

.Review{
    .img-container{
        height: 48px;
        width: 48px;
        img{
            border-radius: 50%;
            height: 100%;
            width: 100%;
        }
    }
}

.login{
    .modal-content{
        height: max-content;
         width: 35%;
         @media (max-width: 992.98px) {
            width: 50%;
          }
          @media (max-width: 765.98px) {
            width: 80%;
          }
          @media (max-width: 574.98px) {
            width: 95%;
          }
    }
    .register.modal-content{
        width: 50%;
       .modal-body{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        input {
            margin: 0;
        }
        .form-floating{
            padding-inline-end: 10px;
            width: 100%;
            label{
                transform: scale(0.85) translateY(-1.5 rem) translateX(0.5rem);
            }
            @media (max-width: 574.98px) {
                    width: 90%;
                }
        }
       }
    
        @media (max-width: 992.98px) {
            width: 90%;
          }
          @media (max-width: 765.98px) {
            width: 80%;
          }
          @media (max-width: 574.98px) {
            width: 95%;
          }
    }
    .alert-container{
        display: flex;
        justify-content: center;
        position: relative;
        padding: 5px;
        margin: 5px auto;
        .alert{
        position: absolute;
        padding: 5px;
        margin: auto;
        .spinner-border{
            height: 15px;
            width: 15px;
        }
    }
    }
}

.api-section{
    padding: 75px 0;
    h2{
        color: #6d5e99;
        margin-bottom: 2.5rem;
    }
    p{
        margin: 3rem 5px;
        color: var(--text-color);
    }
    .img-container{
        img{
            width: 100%;
        }
    }
}

.Features{
    padding: 100px 0 ;
    h2{
        text-align: center;
        width: max-content;
        margin: 60px auto;
        color: var(--primeryColor);
    }
    .row{
        align-items: stretch;
    }
    .card{
        height: 96%;
        margin: .5rem auto;
        border: none !important;
        h5{
            text-align: center;
        }
        .img-container{
            border-radius: 7px;
            padding: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #999;
            width: 60px;
            height: 60px;
            color: #fff;
            margin: 1rem auto;
            box-shadow: 0px 1px 20px -1px #ddd;
            img{
                width: 100%;
            }
        }
    }
}

.Screens{
    transition: all .5s;
    margin: 100px auto;
    background-image: linear-gradient(to right bottom,  var(--primeryColor2), #6d5e99, var(--primeryColor), var(--primeryColor));
    background: var(--primeryColor);
    .row{
        align-items: center;
    }
    .text-container{
        margin: auto;
        max-width: fit-content;
        color: var(--thirdColor);
        @media  (min-width: 1200px) {
            padding-left: 2rem;
          }
          @media (max-width: 1199.98px) {
            margin: 75px 1rem;
            padding: .5rem;
          }
    }
    
    .img-container{
        img{
            width: 100%;
        }
    }
    .svg-container{
        width: fit-content;
    }
    &.rtl-dir{
        .img-container{
            img{
                transform: rotateY(180deg);
            }
        }
        .svg-container svg{
            transform: rotateY(180deg);
        }
    }
}

.ModalPic{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.664);
    height: 100vh;
    width: 100vw;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    &::after{
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        filter: blur(8px);
        -webkit-filter: blur(8px);
        backdrop-filter: blur(2px);

        z-index: 2;
    }
    button{
        z-index: 15;
        position: absolute;
        top: 2rem;
        left: 2rem;
        background: #0000;
        color: var(--thirdColor);
        border: none;
        border-radius: 50%;
        svg{
            box-shadow: 0 0 27px -5px #212529;
        }
    }
    .img-container{
        z-index: 15;
        position: relative;
        max-width: 90%;
        max-height: 95%;
        height: fit-content;
        img{
            max-width: 100%;
            max-height: 100%;
            border-radius: 12px;  
            cursor: pointer;
            transition: all .5s;
            &.zoomed-in{
                cursor: zoom-out;
                transform: scale(2);
            }
            &.zoomed-out{
                cursor: zoom-in;
                transform: scale(1);
            }
        }
    }
}
.pics-container{
    .img-container{
        img{
            width: 95%;
            cursor: pointer;
            border-radius: 8px;
            margin: auto;
        }
    }
}
.footer{
    position: static;
    bottom: 0;
    width: 100%;
    padding: 50px 0 0 0;
    text-align: center;
    background-color: var(--secwhiteColor);
    color: var(--text-color);
    text-align: left;
    z-index: -1;
    .company-logo{
        width: max-content;
        display: inline-block;
        height: 46px;
        svg{
            height: 100%;
        }
    }
    h4{
        width: max-content;
        display: inline-block;
        margin: auto 0;
        margin: auto 0 auto 1rem;
    }
    .contact-info{
        svg{
            margin: auto 5px;
        }
    }
}

.footer .container{
    padding-bottom: 10px;
}
.footer p{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.footer  h4{
    margin-bottom: 1rem;
}
.footer ul{
    list-style: none;
    padding: 1rem 5px;
}
.footer li{
    margin-bottom: 7px;
}
.footer .footer-links ul{
    margin-left: 1rem;
    margin-bottom: 0px !important;
}
.footer li span{
    margin-left: 1rem;
}
.footer .footer-links li{
    cursor: pointer;
}

.footer .footer-links li:hover{
    text-decoration: underline;
}
.footer .footer-copyright{
    background: #00000070;
    color: var(--thirdColor);
    padding: 3px;
    text-align: center;
}

.footer .footer-icons{
    display: flex;
    width: 155px;
    margin: 10px 0 0 auto;
    padding: 0;
    justify-content: center;
}
.footer .footer-icons li{
    margin: 1rem;
}

.footer .footer-icons svg{
    height: 20px;
    fill: var(--text-color);
    cursor: pointer;
}
.footer .footer-icons svg:hover{
    fill: #aaa;
}
svg.link{
    overflow: visible;
}


.lang-btn{
    cursor: pointer;
    padding: 0 5px;
    margin: auto 11px;
    border-radius: 3px;
    border: 1px solid;
}

.App.rtl-font .form-floating > .form-control:focus ~ label, .App.rtl-font .form-floating > .form-control:not(:placeholder-shown) ~ label, .App.rtl-font .form-floating > .form-select ~ label{
    transform: scale(0.85) translateY(-1.8rem) translateX(0.5rem) !important;
}
.check-boxes-container{
    padding: 5px 1rem;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.SelectUserType{
    label{
        padding: 5px 7px;
        border: 1px solid #aaa;
        border-radius: 3px;
        margin: 1rem .25rem;
        cursor: pointer;
        color: #777;
        font-weight: bold;
        width: 5.5rem;
        text-align: center;
        &.checked{
            border-color: #5e4894;
            color: #5e4894;
        }
    }
}

.rtl-font{
    .SelectUserType{
        label{
            width: 4.5rem;
        }
    }
}